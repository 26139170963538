export enum activeFieldIds {
    player,
    opponent,
}
export enum statisticsFields {
    hits,
    miss,
    alive,
    destroyed,
}
