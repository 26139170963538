export const inputs = [
    {
        type: 'password',
        name: 'oldpassword',
        title: 'Old password',
    },
    {
        type: 'password',
        name: 'password',
        title: 'New password',
    },
];
