export const RUSSIAN = {
    buttons: {
        home: 'МЕНЮ',
        ready: 'ГОТОВ',
        close: 'ВЫЙТИ',
        switch: 'ПЕРЕКЛЮЧИТЬ',
        update: 'ОБНОВИТЬ',
        forum: 'ФОРУМ',
        leaders: 'ЛИДЕРЫ',
        profile: 'ПРОФИЛЬ',
        logout: 'ВЫЙТИ',
        login: 'ВОЙТИ',
        signup: 'SIGN UP',
        play: 'ИГРАТЬ',
        auto: 'АВТО',
        reset: 'СБРОС',
        start: 'СТАРТ',
        send: 'ТЫК!',
        editprofile: 'ИЗМЕНИТЬ ПРОФИЛЬ',
        editpass: 'ИЗМЕНИТЬ ПАРОЛЬ',
        confirm: 'ПОДТВЕРДИТЬ',
        back: 'НАЗАД',
        change: 'EN',
        add: 'ОК',
        edit: 'ОК',
        delete: 'ДА',
        ships: 'КОРАБЛИ',
        random: 'СЛУЧАЙНЫЙ',
    },
    labels: {
        chats: 'ЧАТЫ',
        finder: 'ПРИГЛАШЕНИЯ',
        menu: 'МЕНЮ',
        forum: 'ФОРУМ',
        leaders: 'ТАБЛИЦА ЛИДЕРОВ',
        player: 'ИГРОК',
        wins: 'ПОБЕДЫ',
        defeats: 'ПОРАЖЕНИЯ',
        points: 'ОЧКИ',
        default: 'СТАНДАРТНЫЕ',
        custom: 'СВОЙ ФАЙЛ',
        information: 'ИНФОРМАЦИЯ',
        online: 'ОНЛАЙН',
        classic: 'КЛАССИКА',
        theme: 'Тема',
        add: 'Добавить ТОПИК',
        editTopic: 'Изменить ТОПИК',
        editComment: 'Изменить Комментарий',
        deleteTopic: 'Удалить ТОПИК',
        deleteCommnet: 'Удалить Комментарий',
        auth: 'АВТОРИЗАЦИЯ',
        reg: 'РЕГИСТРАЦИЯ',
        find: 'ПОИСК',
    },
    text: {
        cancelGame: 'Игра была отменена',
        waiting: 'Ждём вашего соперника',
        closeGame: 'Вы действительно хотите закончить игру?',
        editTopic: 'Опиши свой топик',
        editComment: 'Опиши свой комментарий',
        avatar: 'добавь аватар со своего компьютера',
        information1: `Предлагаем Вам здорово провести время в нашем приложении!
            Тут вы можете попробовать силы против одного из наших
            могучих роботов пиратов или сыграть с вполне себе реальным
            человеком :)`,
        information2: 'Морской бой...',
        information3: `это игра для двух участников, в которой игроки по очереди
        называют координаты на неизвестной им карте соперника. Если
        у соперника по этим координатам имеется корабль (координаты
        заняты), то корабль или его часть «топится», а попавший
        получает право сделать ещё один ход. Цель игрока — первым
        потопить все корабли противника.`,
        information4: `Поле каждого игрока состоит из 100 клеток (10 на 10). В
        начале игры необходимо разместить 10 кораблей: 4
        однопалубных, 3 двухпалубных, 2 трехпалубных, 1
        четырехпалубный. Обратите внимание, между кораблями всегда
        должна быть одна клетка.`,
        information5: 'Правила знаем, давай играть!',
        information6: 'Чтобы начать, Вам необходимо авторизироваться.',
        information7: `Авторизировались? Отлично! Можно выбирать режим игры на
        главной странице и начинать игру!`,
        information8:
            'Если Вы выбрали онлайн режим, то необходимо найти соперника.',
        information9: 'Выберите оппонента сами или нажмите кнопку РАНДОМ.',
        information10: 'Игрок получит от Вас приглашение, ожидайте :)',
        information11: 'Начало игры',
        information12: `Пора расставить корабли! Сделать это можно в автоматическом
        или ручном режиме, перетаскивая каждый корабль. Чтобы
        повернуть корабль, перетащите его на поле и щёлкните по нему
        ПКМ.`,
        information13: 'Игра началась!',
        information14: `На каждый ход даётся 15 секунд. Если Вы не успели - ход
        переходит сопернику.`,
        information15: `Поля меняются автоматически, однако вы можете переключать их
        сами кнопкой:`,
        information16: `Чтобы онлайн боталии были ещё более интересными, в данном
        режиме доступен чат!`,
        information17: 'Не увлекайтесь, время тикает! :)',
        information18: 'Остальное',
        information19: `Очки, полученные в онлайн режиме, сохраняются. Сравнить свой
        результат с другими игроками можно на странице с
        лидербордом!`,
        information20: `Обсудить игру, поблагодарить разработчиков, отметить
        найденный баг, оставить отзыв или поделиться интересной
        темой можно на нашем форуме!`,
        information21: `Если во время игры завязалась увлекательная беседа Вы можете
        продолжить на странице встроенного мессенджера!`,
        information22: `Каждой игрок может дополнять и редактировать свои данные.
        Для этого зайдите в свой профиль.`,
        information23: 'Приложение доступно на двух языках.',
        information24: 'Желаем приятной игры!',
        togglemode: 'Выбери режим игры!',
        forum: 'Отправь комментарий...',
        deleteTopic: 'Ты точно хочешь удалить топик?',
        deleteCommnet: 'Ты точно хочешь удалить комментарий?',
    },
};
