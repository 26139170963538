export const headers = {
    title: 'BATTLESHIP',
    navigation: 'sign up',
};

export const inputs = [
    {
        name: 'email',
        title: 'E-mail',
    },
    {
        type: 'password',
        name: 'password',
        title: 'Password',
    },
];

export const submitTitle = 'SIGN IN!';
